import styled, { useTheme } from 'styled-components';
import { Stack } from '@koob/margaret';
import { useTranslation } from 'react-i18next';
import { IoPeopleOutline } from 'react-icons/io5';
import { IcPencil } from 'components/icons';
import { Tooltip } from 'components';
import { Text, SectionCard } from 'ui';
import { formatCurrency } from 'utils';
import HotelRoomPromotion from './HotelRoomPromotion';
import IcLightning from 'components/icons/IcLightning';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { uniqBy } from 'lodash';

const CrossedPrice = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.textLighter};
  text-decoration: line-through;
`;

const CardTitle = styled(Text).attrs({ type: 'bodyLarge' })`
  font-weight: bold;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const Legend = styled(Text).attrs({
  color: 'textLighter',
  type: 'bodySmall',
})``;

const UppercaseText = styled(Text)`
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.textLight};
`;

const DmcContractHotelRooms = ({
  rooms,
  numberOfNights,
  roomsForRecap,
  modifyCurrentRooms,
  setCurrentSelectedBedIndex,
  currentSelectedBedIndex,
  updateRoomFromPromotion,
  promoList,
  optionalPromo,
  setOptionalPromo,
  selectedRooms,
  setSelectedPromo
}) => {
  const { t } = useTranslation(['bookings', 'hotelRoom']);
  const theme = useTheme();
  const [selectedPromotionForRoom, setSelectedPromotionForRoom] = useState(
    Array(rooms.length).fill(0),
  );
  const [roomsForRecapPrevState, setRoomsForRecapPrevState] = useState(
    JSON.stringify(roomsForRecap),
  );
  const [selectedOptionalPromos, setSelectedOptionalPromos] = useState([]);

  const supplementTypes = ['supplement', 'supplement_percent'];

  useEffect(() => {
    let tmp = [...selectedPromotionForRoom];
    const currentRoomsForRecapState = JSON.stringify(roomsForRecap);

    if (currentRoomsForRecapState !== roomsForRecapPrevState) {
      const previousRooms = JSON.parse(roomsForRecapPrevState);
      const currentRooms = JSON.parse(currentRoomsForRecapState);

      currentRooms.forEach((currRoom, index) => {
        const previousRoom = previousRooms[index];
        if (previousRoom?.name !== currRoom.name) {
          tmp[index] = 0;
        }
      });
      if (currentRooms.length < tmp) {
        tmp = tmp
          .slice(0, currentRooms.length)
          .concat(new Array(tmp.length - currentRooms.length).fill(0));
      }
      setRoomsForRecapPrevState(currentRoomsForRecapState);
      setSelectedPromotionForRoom(tmp);
      setOptionalPromo([]);
      if (!selectedRooms?.find(item => !!(item?.bed?.id))) {
        setSelectedOptionalPromos([])
      }
    }
  }, [
    roomsForRecap,
    roomsForRecapPrevState,
    selectedPromotionForRoom,
    setOptionalPromo,
  ]);

  const handleUpdatedPromos = (index, value) => {
    setSelectedOptionalPromos(prev => {
      const updatedPromos = {
        index,
        promos: value.map(promo => ({ ...promo })),
      };
      const updatedArray = [...prev];
      updatedArray[index] = updatedPromos;
      return updatedArray;
    });
  };

  return (
    <Stack direction="column" size="full" gutterSize={0.5}>
      <Stack paddingBottom={0.5}>
        <Text type="h2">{t('mySelection')}</Text>
      </Stack>

      {roomsForRecap.map((selectedRoom, roomSelectedIndex) => {
        const promotionInfo = selectedRoom?.bed?.promotionInfo;
        const isSupplementPromo = promotionInfo?.kind && !supplementTypes.includes(promotionInfo?.kind);
        const promoId = isSupplementPromo ? promotionInfo?.promotionId : undefined

        let promotionIndex = 0;
        const optionalPromoList = uniqBy(
          (promoList || []).filter(({ id }) => {
            return Boolean(id);
          }),
          'displayName',
        );

        const promoOptional = selectedOptionalPromos[roomSelectedIndex]?.promos;
        const OptionalPromoprices = (promoOptional || []).reduce(
          (total, { price }) => total + price,
          0,
        );
        const OptionalPromoPriceInDollar = (promoOptional || []).reduce(
          (total, { priceInDollar }) => total + priceInDollar,
          0,
        );
        const promotionOptions =
          selectedRoom?.allPromotions
            ?.flatMap(promotionCombinations => {
              return Object.entries(promotionCombinations).map(
                ([key, promotionCombination]) => {
                  if (
                    selectedRoom?.bed.id &&
                    selectedRoom?.bed.id.split('##').pop() ===
                      key.split('##').pop() &&
                    promotionCombination.length > 0
                  ) {
                    const result = {
                      label: promotionCombination.join(', '),
                      value: promotionIndex,
                      index: promotionIndex,
                      promotions: promotionCombination,
                      promotionId: promoId,
                      bedName: selectedRoom?.bed.name,
                      originalIndex: selectedRoom.originalIndex,
                    };
                    promotionIndex++;
                    return result;
                  } else {
                    return null;
                  }
                },
              );
            })
            .filter(item => item !== null) ?? [];
        const uniqueDataPromo = Object.values(
          promotionOptions?.reduce(
            (acc, obj) => ({ ...acc, [obj.label]: obj }),
            {},
          ),
        );

        const promotions = !supplementTypes.includes(
          selectedRoom?.bed?.promotionInfo?.kind,
        )
          ? selectedRoom?.bed?.promotions
          : [];
        const allMandatorySupplements = selectedRoom?.bed?.mandatorySupplements;
        const mandatorySupplements = Object.entries(
          (allMandatorySupplements || []).reduce((acc, val) => {
            acc[val] = (acc[val] || 0) + 1;
            return acc;
          }, {}),
        ).map(([key, value]) => `${key} (x${value})`);
        const options = (selectedRoom.bedChoices ?? []).map(
          (bedChoice, bedIndex) => {
            return {
              value: bedChoice?.id,
              label: bedChoice?.nameWithPrice,
              bedIndex: bedIndex,
              roomIndex: roomSelectedIndex,
            };
          },
        );
        const selectedOption = options.find(
          elem =>
            elem.value.split('##').pop() ===
            selectedRoom?.bed?.id.split('##').pop(),
        );

        const optionalSupplementValue = selectedRooms[roomSelectedIndex]?.optionalPromos ?? optionalPromo
        
        return (
          <SectionCard
            key={roomSelectedIndex}
            isSelected={roomSelectedIndex === roomsForRecap.length - 1}
            onClick={() => {
              if (roomSelectedIndex !== roomsForRecap.length - 1) {
                modifyCurrentRooms(roomSelectedIndex);
                setSelectedOptionalPromos([]);
              }
            }}
          >
            <Stack direction="column" size="full">
              <Stack direction="column" size="full" gutterSize={0.125}>
                <Stack
                  size="full"
                  gutterSize={0.25}
                  alignX="space-between"
                  alignY="baseline"
                >
                  <div>
                    <UppercaseText color="textLighter">
                      {t('roomN', { count: roomSelectedIndex + 1 })}
                    </UppercaseText>
                  </div>

                  <IconWrapper>
                    <IcPencil size={20} />
                  </IconWrapper>
                </Stack>

                <Stack
                  size="full"
                  gutterSize={0.125}
                  alignX="space-between"
                  alignY="baseline"
                  direction="column"
                >
                  <Stack direction="column" gutterSize={0.375}>
                    <CardTitle>{selectedRoom?.name}</CardTitle>

                    <Legend>
                      <Stack gutterSize={0.25} alignY="center">
                        <IoPeopleOutline size={18} />

                        <span>
                          {t('nGuests', {
                            count:
                              rooms[roomSelectedIndex].adults +
                              rooms[roomSelectedIndex].children,
                          })}
                        </span>
                      </Stack>
                    </Legend>
                  </Stack>

                  {options?.length > 1 && (
                    <Stack
                      direction="column"
                      gutterSize={0.5}
                      style={{ paddingBlock: theme.spacing(0.5) }}
                    >
                      <Text type="bodySmall">
                        {t('composition', { ns: 'hotelRoom' })}
                      </Text>

                      <Select
                        value={selectedOption}
                        options={options}
                        disabled={selectedRoom?.isLast}
                        onChange={value => {
                          setCurrentSelectedBedIndex(value.bedIndex);
                        }}
                      />
                    </Stack>
                  )}

                  <Stack
                    direction="column"
                    gutterSize={0.5}
                    style={{ paddingBlock: theme.spacing(0.5) }}
                  >
                    {uniqueDataPromo?.length > 1 && (
                      <>
                        <Text type="bodySmall">{t('choosePromotion')}</Text>

                        <Select
                          value={
                            uniqueDataPromo[
                              selectedPromotionForRoom[roomSelectedIndex]
                            ]
                          }
                          options={uniqueDataPromo}
                          onChange={value => {
                            const tmp = [...selectedPromotionForRoom];
                            tmp[roomSelectedIndex] = value.index;
                            setSelectedPromotionForRoom(tmp);
                            setCurrentSelectedBedIndex(currentSelectedBedIndex);
                            updateRoomFromPromotion(
                              value.originalIndex,
                              value.label,
                              value.bedName,
                              selectedRoom,
                            );
                            setSelectedPromo(value)
                          }}
                        />
                      </>
                    )}

                    {(optionalPromoList || []).length > 0 && (
                      <Select
                        placeholder={t('supplement')}
                        options={optionalPromoList}
                        value={optionalSupplementValue}
                        getOptionValue={value => value?.id}
                        getOptionLabel={value => value?.displayName}
                        onChange={value => {
                          setOptionalPromo(value);
                          handleUpdatedPromos(roomSelectedIndex, value);
                        }}
                        isMulti
                        isSearchable={false}
                        className="basic-multi-select"
                      />
                    )}
                  </Stack>

                  <Stack
                    style={{
                      flexWrap: 'wrap',
                      gap: '0.5rem',
                      marginBlock: theme.spacing(0.5),
                    }}
                  >
                    {promotions?.length > 0 &&
                      promotions.map(
                        (promotion, index) =>
                          promotion !== null && (
                            <HotelRoomPromotion name={promotion} key={index} />
                          ),
                      )}

                    {mandatorySupplements?.length > 0 &&
                      mandatorySupplements.map((supplement, index) => (
                        <Tooltip
                          key={index}
                          tip={t('requiredSupplement')}
                          position="top"
                          hasArrow={false}
                          color="purple"
                        >
                          <HotelRoomPromotion
                            bgColor="#3AEDCC"
                            name={supplement}
                          />
                        </Tooltip>
                      ))}
                  </Stack>

                  <Stack
                    style={{
                      flexWrap: 'wrap',
                      gap: '0.5rem',
                      marginBlock: theme.spacing(0.5),
                    }}
                  >
                    {(optionalSupplementValue || []).map(({ displayName }, index) => (
                      <HotelRoomPromotion name={displayName} key={index} />
                    ))}
                  </Stack>

                  <Stack alignX="flex-end" gutterSize={0.5}>
                    {selectedRoom?.bed?.totalPriceInDollar?.withPromo && (
                      <Stack
                        direction="column"
                        alignX="flex-end"
                        className="border p-2 rounded-xl"
                      >
                        <Stack gutterSize={0.5} alignY="center">
                          {selectedRoom?.bed?.totalPriceWithPromo !==
                            selectedRoom?.bed?.totalPriceWithoutPromo && (
                            <CrossedPrice style={{ whiteSpace: 'nowrap' }}>
                              {formatCurrency({
                                amount:
                                  selectedRoom?.bed?.totalPriceInDollar
                                    ?.withoutPromo,
                                currency: 'usd',
                              })}
                            </CrossedPrice>
                          )}

                          <Text
                            type="bodyLarge"
                            color="text"
                            fontWeight={700}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {formatCurrency({
                              amount:
                                parseFloat(
                                  selectedRoom?.bed?.totalPriceInDollar
                                    ?.withPromo ?? '0',
                                ) + OptionalPromoPriceInDollar,
                              currency: 'usd',
                            })}
                          </Text>
                        </Stack>

                        <Legend>
                          {t('pricePerNight', {
                            price: formatCurrency({
                              amount:
                                (parseFloat(
                                  selectedRoom?.bed?.totalPriceInDollar
                                    ?.withPromo + OptionalPromoPriceInDollar ??
                                    '0',
                                ) +
                                  OptionalPromoPriceInDollar) /
                                numberOfNights,
                              currency: 'usd',
                            }),
                          })}
                        </Legend>
                      </Stack>
                    )}

                    <Stack
                      direction="column"
                      alignX="flex-end"
                      className="border p-2 rounded-xl"
                    >
                      <Stack gutterSize={0.5} alignY="center">
                        {selectedRoom?.bed?.totalPriceWithPromo !==
                          selectedRoom?.bed?.totalPriceWithoutPromo && (
                          <CrossedPrice style={{ whiteSpace: 'nowrap' }}>
                            {formatCurrency({
                              amount: selectedRoom?.bed?.totalPriceWithoutPromo,
                              currency: selectedRoom?.bed?.currency,
                            })}
                          </CrossedPrice>
                        )}

                        <Text
                          type="bodyLarge"
                          color="text"
                          fontWeight={700}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {formatCurrency({
                            amount:
                              selectedRoom?.bed?.totalPriceWithPromo +
                              OptionalPromoprices,
                            currency: selectedRoom?.bed?.currency,
                          })}
                        </Text>
                      </Stack>

                      <Legend>
                        {t('pricePerNight', {
                          price: formatCurrency({
                            amount:
                              (selectedRoom?.bed?.totalPriceWithPromo +
                                OptionalPromoprices) /
                              numberOfNights,
                            currency: selectedRoom?.bed?.currency,
                          }),
                        })}
                      </Legend>
                    </Stack>

                    {selectedRoom?.allotmentAvailable && (
                      <Tooltip
                        tip={
                          selectedRoom?.supplier?.toUpperCase() === 'KOEDIA'
                            ? t('connectedChannelManager:tooltip')
                            : t('allotments:tooltip')
                        }
                        hasArrow={false}
                        color="purple"
                      >
                        <IcLightning size={24} />
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </SectionCard>
        );
      })}
    </Stack>
  );
};

export default DmcContractHotelRooms;
